import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

import AnimationWrapper from "./animationWrapper"
import Container from "./container"
import TextSection from "./textSection"

import "./lightboxImageGrid.css"

const LightboxImageGrid = ( { data, className } ) => {

  console.log('data', data);

  // Remove inactive images from the 'images' array
  data.images = data.images.filter(image => image.status === "active");

  const options = {
    settings: {
      // overlayColor: "rgb(25, 136, 124)",
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    // buttons: {
    //   backgroundColor: "#1b5245",
    //   iconColor: "rgba(126, 172, 139, 0.8)",
    // },
    caption: {
      // captionColor: "#a6cfa5",
      captionFontFamily: "Titillium Web, sans-serif",
      captionFontWeight: "400",
      captionFontSize: "1.5rem",
      // captionTextTransform: "uppercase",
    }
  };

    return (
      <>

        <AnimationWrapper>
          <div className="description-container">
            {data.text_section.active &&
              <Container>
                <AnimationWrapper>
                  <TextSection data={data.text_section} />
                </AnimationWrapper>
              </Container>
            }
          </div>
        </AnimationWrapper>

        <Container className={`lightbox-image-grid ${className}`} >
          <SimpleReactLightbox>
            <SRLWrapper options={options}>
              {data.images.map((image) => (
                <div className="lighbox-image-wrapper" key={image.id}>
                  <GatsbyImage
                    image={image.image.childImageSharp.gatsbyImageData}
                    objectFit={image.image_object_fit ? image.image_object_fit : "cover"}
                    objectPosition={image.image_object_position ? image.image_object_position : "50% 50%"}
                    alt={image.title}
                  />
                  <div className="image-overlay">
                    {image.title &&
                      <div className="image-caption">{image.title}</div>
                    }
                  </div>
                </div>
              ))}
            </SRLWrapper>
          </SimpleReactLightbox>
        </Container>

      </>
    )
  }

export default LightboxImageGrid